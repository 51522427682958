<template>
  <v-app>
    <v-content>
      <router-view name="primary"></router-view>
    </v-content>

    <v-snackbar
      v-model="showCookieConsent"
      :multi-line="true"
      :timeout="0"
      :bottom="true"
      :vertical="false"
      class="termsConsent"
    >
      {{ $t('legal_agreement_confirmation') }}
      <v-btn
        text
        :to="{ name: 'terms' }"
      >
      {{ $t('terms') }}
      </v-btn>
      <v-btn
        dark
        text
        icon
        @click="$store.dispatch('setCookieConsent', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <snackbar ref="snackbar"></snackbar>
    <v-overlay
      opacity="1"
      :dark="$store.state.app.dark"
      :color="($store.state.app.dark) ? 'grey darken-4' : 'grey lighten-5'"
      :value="$store.state.app.loading"
      z-index="99999"
    >
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
export default {
  created () {
    this.$vuetify.theme.dark = this.$store.state.app.dark
    this.$root.$snackbar = this.$refs.snackbar
  },
  computed: {
    showCookieConsent () {
      return this.$store.state.app.showCookieConsent
    }
  }
};
</script>